.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  padding: 8rem 10rem;
}

.leftCol {
  display: flex;
  flex-direction: column;
  width: 40vw;
}

.leftCol span {
  font-size: 5rem;
  font-weight: 800;
  color: #fff;
  line-height: 1.2;
}

.leftCol button {
  margin-top: 5rem;
  width: 24rem;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8rem;
}

.logo svg {
  height: 6rem;
  margin-right: 1.5rem;
}

.logo span {
  font-size: 2rem;
  line-height: 1.2;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1.6rem;
}

.alert {
  width: 100%;
  text-transform: uppercase;
  background: #dc2626;
  color: white;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1em;
  font-size: 1.6em;
  padding: 0.8em;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  width: 36rem;
  border-radius: 0.5rem;
  background-color: white;
}

.card span {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
}

.title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  text-align: left;
  width: 100%;
}
